<template>
	<div class="my-help">
		<div class="my-title clearfix">
			<span class="">帮助中心</span>
		</div>
		<el-collapse v-model="activeNames" @change="handleChange">
			<el-empty :image-size="50" description="暂无信息" v-if="list.length==0"></el-empty>
			<el-collapse-item v-for="(item,index) in list.data" :key="index" :title="item.title" :name="item.id">
				<div>{{ item.content }}</div>
			</el-collapse-item>
		</el-collapse>
		<!-- 分页 -->
		<div class="paging">
			<el-pagination @current-change="handleCurrentChange" background
				:current-page.sync="page" :page-size="100" layout="prev, pager, next, jumper" :total="list.total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import * as HelpApi from '@/api/help'
	export default {
		data() {
			return {
				activeNames: ['1'],
				page: 1,
				list: []
			};
		},
		created() {
			this.getList()
		},
		methods: {
			getList(){
				let app = this
				HelpApi.list({ page: app.page })
				  .then(result => {
				    // 合并新数据
				    app.list = result.data.list
				  })
			},
			handleChange(val) {
				// console.log(val);
			},
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.page = val
				this.getList()
			},
		}
	}
</script>

<style lang="scss" scoped>
	.my-help {
		background: #FFF;
	}
	.el-collapse-item__header{
		font-size: 16px;
	}
	.paging{
		text-align: center;
		margin-top: 20px;
	}
</style>
